import { Modal } from "@mui/material";
import PropTypes from "prop-types";
import HomePageButtons from "./HomePageButtons";

const HomePageApplyButtonModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="modal-background home-modal-background"
    >
      <HomePageButtons handleClose={handleClose} />
    </Modal>
  );
};

HomePageApplyButtonModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default HomePageApplyButtonModal;
