import { useMediaQuery } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PropTypes from "prop-types";
import IconButton from "../icon-btn";
import { useNavigate } from "react-router-dom";

const GenericBack = ({ trackingDetails, isLoading = false }) => {
  const navigate = useNavigate();
  const tablet = useMediaQuery("(max-width:900px)");

  const goBack = () => {
    if (document?.referrer?.includes(window?.location?.origin)) {
      navigate(-1); // Client-side navigation
    } else {
      window?.history?.back(); // External navigation
    }
  };

  return (
    <>
      {tablet ? (
        <IconButton
          trackingDetails={trackingDetails}
          buttonTitle="Back"
          styleClass="light-backbtn"
          handleClick={goBack}
          disabled={isLoading}
          link="#"
          icon={<KeyboardArrowLeftIcon />}
        />
      ) : null}
    </>
  );
};

GenericBack.propTypes = {
  trackingDetails: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default GenericBack;
