import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [dialogs, setDialogs] = useState([]);

  const openDialog = (dialogName) => {
    history.pushState({ dialogOpen: true, dialogName }, "Dialog Open");
    setDialogs((prev) => [...prev, dialogName]);
  };

  const closeDialog = (dialogName) => {
    if (dialogName) {
      setDialogs((prev) => prev.filter((name) => name !== dialogName));
    } else {
      setDialogs([]);
    }
  };

  const closeLatestDialog = () => {
    if (dialogs?.length) {
      closeDialog(dialogs[dialogs?.length - 1]);
    }
  };

  const handlePopState = (event) => {
    if (event?.state?.dialogOpen) {
      const { dialogName } = event.state;
      if (!dialogs?.includes(dialogName)) {
        setDialogs((prev) => [...prev, dialogName]);
      } else {
        closeLatestDialog();
      }
    } else {
      closeLatestDialog();
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [dialogs]);

  return (
    <DialogContext.Provider value={{ dialogs, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

DialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useDialog() {
  return useContext(DialogContext);
}
