import { Box, Typography, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import useStepper from "../../../../../hooks/useStepper";
import { InvestmentChoicesStep2ValidationSchema } from "../../../../../schema";
import FormSelect from "../../../../generic-components/form-select";
import FooterButtons from "../footer/FooterButtons";
import InvestmentChoicesStep2Provider from "./InvestmentChoicesStep2Provider";
import Loader from "../../../../generic-components/loader";
import InvestmentChoices from "./InvestmentChoices";
import { useLazyQuery, useMutation } from "@apollo/client";
import { updateFundDetails } from "../../../../../graphql/mutations/updateFundDetails";

import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setForm } from "../../../../../reducers/formReducer";
import {
  createInvestmentChoice,
  deleteInvestmentChoice,
} from "../../../../../graphql/mutations/investmentChoice";
import moment from "moment";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import { getPensionFunds } from "../../../../../graphql/queries/pensionFunds";
import { getProviders } from "../../../../../graphql/queries/providers";
import Dialog from "../../../../generic-components/Dialog";
import { updateRecommendationMutation } from "../../../../../graphql/mutations/updateRecommendation";
import NINGI_JOURNEY_STATUS from "../../../../../constants/ningiJourneyStatus";
import { dialogName, TRACKING_NAF_OPTIONS } from "../../../../../constants";
import { updateUserConsents } from "../../../../../graphql/mutations/updateUserConsents";
import SavePlan from "../../../../generic-components/save-plan";
import { transformTempInvestmentChoices } from "../../../../../helpers";
import { isNHSApplication } from "../../../../../reducers/multiFormReducer";
import useDialog from "../../../../../hooks/useModal";

const InvestmentChoicesStep2 = ({
  form,
  formSharedTitle,
  pensionName,
  isNHSApplication,
}) => {
  const mobile = useMediaQuery("(max-width:600px)");
  const {
    setSaveError,
    handleNext,
    btnClicked,
    setIsLoadingNext,
    activeStep,
    setFormValues,
    formValues,
    personalDetails,
    loading,
    setIsLoadingSave,
    setSavedToast,
    setErrorToast,
    showJourneyCompleteDialog,
    setJourneyCompleteDialog,
  } = useStepper();
  const { dialogs, openDialog, closeDialog } = useDialog();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(true);
  const [changedFieldName, setChangedFieldName] = useState("");
  const [pensionFunds, setPensionFunds] = useState([]);
  const [providers, setProviders] = useState([]);

  const initialFormValues = {
    pensionFund: formValues.pensionFund,
    provider: formValues.provider,
    MPAAdate: formValues.MPAAdate || "",
    flexiblyAccessedDate: formValues.flexiblyAccessedDate || "",
    accessedPensionBtn: formValues.accessedPensionBtn,
    RnUCheckbox: formValues.RnUCheckbox,
    listCheckbox: !formValues.lifestyleCheckbox,
    lifestyleCheckbox: formValues.lifestyleCheckbox,
    investmentChoices: formValues.investmentChoices,
    totalPercent: formValues.totalPercent,
    retirementAge: formValues.retirementAge,
    pensionAge: formValues.pensionAge,
    isNHSApplication: isNHSApplication,
    previousEmployerPlan: formValues.previousEmployerPlan,
    combiningWithThisEmployer: formValues.combiningWithThisEmployer,
    keepSeparateWithEmployer: formValues.keepSeparateWithEmployer,
    triggeredTheMpaa: formValues.triggeredTheMpaa,
  };

  const [updateFunds] = useMutation(updateFundDetails);
  const [updateRecommendation, { loading: isRecommendationLoading }] =
    useMutation(updateRecommendationMutation);

  const [updateUserConsent, { loading: isUserConsentUpdating }] =
    useMutation(updateUserConsents);
  const [tempInvestmentChoice] = useMutation(createInvestmentChoice);
  const [deleteTempInvestment] = useMutation(deleteInvestmentChoice);

  const createTempInvestment = (values) => {
    dispatch(setForm({ investmentChoices: values.investmentChoices }));

    setFormValues((prevValues) => ({
      ...prevValues,
      ...values,
      investmentChoices: [...values.investmentChoices],
    }));

    const createInvestmentChoices = values.investmentChoices.map((choice) => {
      if (choice.name && form.id && choice.percentage) {
        return {
          investment_option_id: choice.name,
          temp_plan_id: form.id,
          specialist_value: choice.percentage,
          default_option: "true",
          created_at: moment().format(),
          updated_at: moment().format(),
        };
      }
    });

    if (
      Array.isArray(createInvestmentChoices) &&
      createInvestmentChoices.length &&
      createInvestmentChoices.filter(Boolean).length
    ) {
      tempInvestmentChoice({
        variables: {
          investmentOptions: createInvestmentChoices.filter(Boolean),
        },
        onCompleted: (data) => {
          if (data?.insert_temp_plan_investments?.returning?.length) {
            dispatch(
              setForm({
                investmentChoices: transformTempInvestmentChoices(
                  data?.insert_temp_plan_investments?.returning
                ),
                temp_plan_investments:
                  data?.insert_temp_plan_investments?.returning,
              })
            );
          }

          if (btnClicked === "save") {
            setSavedToast(true);
          }
          if (btnClicked === "save_and_next") {
            handleNext();
          }
          setIsLoadingNext(false);
          setIsLoadingSave(false);
        },
        onError: (error) => {
          setIsLoadingNext(false);
          setIsLoadingSave(false);
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else {
      setIsLoadingNext(false);
      setIsLoadingSave(false);
    }
  };

  const [getPensionFundList, { loading: fundsLoading }] = useLazyQuery(
    getPensionFunds,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: personalDetails.organisation_id,
      },
      onCompleted: (data) => {
        const pensionFunds =
          data.pension_funds?.map((fund) => ({
            name: fund.name,
            value: fund.id,
          })) || [];

        setPensionFunds(pensionFunds);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    }
  );

  const [getProvidersList, { loading: providersLoading }] = useLazyQuery(
    getProviders,
    {
      variables: {
        id: personalDetails.organisation_id,
      },
      onCompleted: (data) => {
        const providers =
          data.providers?.map((provider) => ({
            name: provider.name,
            value: provider.id,
          })) || [];

        setProviders(providers);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    }
  );

  const handleUpdate = (values) => {
    setSaveError("");
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else if (btnClicked === "save") {
      setIsLoadingSave(true);
    }
    updateFunds({
      variables: {
        id: form.id,
        flexibly_accessed_any_pensions: values.accessedPensionBtn,
        lifestyling_option: values.lifestyleCheckbox,
        pension_fund_id: values.pensionFund,
        provider_id: values.provider,
        pension_age: values.pensionAge,
        retirement_age: parseInt(values.retirementAge, 10) || null,
        step_number: btnClicked === "save" ? activeStep + 5 : activeStep + 6,
        choosen_relevant_investment_option: values.RnUCheckbox || false,
        having_previous_employer_plan: values.previousEmployerPlan || false,
        combining_with_this_employer: values.combiningWithThisEmployer || false,
        keep_separate_with_employer: values.keepSeparateWithEmployer || false,
        triggered_the_mpaa: values.triggeredTheMpaa || false,
        mpaa_date:
          values.triggeredTheMpaa && values.MPAAdate ? values.MPAAdate : null,
        flexibly_accessed_date:
          values.accessedPensionBtn && values.flexiblyAccessedDate
            ? values.flexiblyAccessedDate
            : null,
        updated_at: moment().format(),
      },
      onCompleted: (data) => {
        dispatch(setForm(data.update_temp_plans.returning[0]));
        deleteTempInvestment({
          variables: {
            temp_plan_id: form.id,
          },
          onCompleted: () => createTempInvestment(values),
          onError: (error) => {
            setIsLoadingNext(false);
            setIsLoadingSave(false);
            setSaveError(error);
            setErrorToast(true);
          },
        });
      },
      onError: (error) => {
        setIsLoadingSave(false);
        setSaveError(error);
        setIsLoadingNext(false);
        setErrorToast(true);
      },
    });
  };

  const handleNingiChange = (fieldName) => {
    if (
      form?.ningi_journey?.journey_status ===
        NINGI_JOURNEY_STATUS.COMPLETED_NINGI_JOURNEY &&
      !form.ningi_journey?.recommendation_changed
    ) {
      openDialog(dialogName.NINGI_RECOMMENDATION_CHANGE);
      setChangedFieldName(fieldName);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPensionFundList();
    getProvidersList();
    if (
      form?.show_ningi_journey_consent &&
      form?.ningi_journey?.journey_status ===
        NINGI_JOURNEY_STATUS.COMPLETED_NINGI_JOURNEY
    ) {
      setJourneyCompleteDialog(true);
    }
  }, []);

  const isLoading = useMemo(
    () => providersLoading || fundsLoading || loading,
    [providersLoading, fundsLoading, loading]
  );

  const resetInvestmentChoices = (_, value, setFieldValue) => {
    setFieldValue("investmentChoices", []);
  };

  const updateConsent = () => {
    setFormValues({
      ...formValues,
      show_ningi_journey_consent: false,
    });
    dispatch(
      setForm({
        show_ningi_journey_consent: false,
      })
    );
    updateUserConsent({
      variables: {
        id: form?.id || 0,
      },
      onCompleted: () => {
        setJourneyCompleteDialog(false);
      },
    });
  };

  const updatePreference = (setFieldValue) => {
    setFormValues({
      ...formValues,
      investment_advice: true,
      confirmCheckbox: true,
      ningi_journey: {
        ...formValues.ningi_journey,
        recommendation_changed: true,
      },
    });
    dispatch(
      setForm({
        investment_advice: true,
        confirmCheckbox: true,
        ningi_journey: {
          ...form.ningi_journey,
          recommendation_changed: true,
        },
      })
    );
    updateRecommendation({
      variables: {
        id: formValues.ningi_journey?.id || 0,
        temp_plan_id: form.id,
      },
      onCompleted: () => {
        switch (changedFieldName) {
          case "provider":
            setFieldValue("investmentChoices", []);
            break;

          case "pensionFund":
            setFieldValue("investmentChoices", []);
            setFieldValue("provider", null);
            break;

          default:
            break;
        }

        closeDialog(dialogName.NINGI_RECOMMENDATION_CHANGE);
      },
    });
  };

  return (
    <Formik
      validationSchema={InvestmentChoicesStep2ValidationSchema}
      initialValues={initialFormValues}
      onSubmit={(values) => handleUpdate(values)}
    >
      {({ values, handleSubmit, setValues, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Box className="investment-choices-step-2">
            {isLoading ? (
              <Box className="mt-30">
                <Loader />
              </Box>
            ) : (
              <>
                <ScrollToFieldError setExpanded={setExpanded} />
                <SavePlan />
                <Box className="application-page-container">
                  <InvestmentChoices />
                  <Typography className="mt-30 stepper-content">
                    <strong>
                      Your employer offers the {pensionName}
                      {""} and it is administered by different Pension Funds.
                    </strong>
                  </Typography>
                  <Typography className="mt-30 stepper-content">
                    Please select which pension fund your {pensionName}
                    {""} is administered by:
                  </Typography>
                  <FormSelect
                    name="pensionFund"
                    labelId="pensionFundList"
                    menuItems={pensionFunds}
                    label={`${pensionName}*`}
                    width={mobile ? 100 : 70}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    handleCustomChange={(_, _1, setFieldValue) => {
                      resetInvestmentChoices(null, null, setFieldValue);
                      handleNingiChange("pensionFund");
                    }}
                  />
                  {values.pensionFund && (
                    <>
                      <Typography className="mt-30 stepper-content">
                        <strong>
                          Your employer offers you the choice of providers that
                          can hold and invest your {formSharedTitle}s.
                        </strong>
                      </Typography>
                      <Typography className="mt-30 stepper-content">
                        Please select which provider you want to use
                      </Typography>
                      <FormSelect
                        name="provider"
                        labelId="providerList"
                        menuItems={providers}
                        label="Providers *"
                        width={mobile ? 100 : 70}
                        trackingDetails={TRACKING_NAF_OPTIONS}
                        handleCustomChange={(_, _1, setFieldValue) => {
                          resetInvestmentChoices(null, null, setFieldValue);
                          handleNingiChange("provider");
                        }}
                      />
                    </>
                  )}
                  {values.pensionFund && values.provider && (
                    <InvestmentChoicesStep2Provider
                      form={form}
                      providers={providers}
                      expanded={expanded}
                      resetInvestmentChoices={resetInvestmentChoices}
                      setExpanded={setExpanded}
                      handleNingiChange={handleNingiChange}
                    />
                  )}
                </Box>
                <Dialog
                  title={
                    "You are changing your investment choices from those given to you in your investment advice journey."
                  }
                  description={
                    "By doing this you acknowledge that you are not taking this advice and want to proceed with your own choices"
                  }
                  classes="change-investment-dialog"
                  saveButtonText={"I understand"}
                  showLoading={isRecommendationLoading}
                  showDialog={dialogs?.includes(
                    dialogName.NINGI_RECOMMENDATION_CHANGE
                  )}
                  trackingDetails={TRACKING_NAF_OPTIONS}
                  handleClose={() => {
                    closeDialog(dialogName.NINGI_RECOMMENDATION_CHANGE);
                    setValues({
                      ...values,
                      listCheckbox: !formValues.lifestyleCheckbox,
                      lifestyleCheckbox: formValues.lifestyleCheckbox,
                      investmentChoices: initialFormValues.investmentChoices,
                      pensionFund: initialFormValues.pensionFund,
                      provider: initialFormValues.provider,
                    });
                  }}
                  handleSave={() => updatePreference(setFieldValue)}
                />
                <Dialog
                  title={`You have completed your advice journey and your recommendation(s) have been pre-populated into your ${formSharedTitle} application form for you.`}
                  classes="change-investment-dialog"
                  saveButtonText={"I understand, let’s proceed!"}
                  showCloseBtn={false}
                  showLoading={isUserConsentUpdating}
                  showDialog={showJourneyCompleteDialog}
                  handleSave={updateConsent}
                  trackingDetails={TRACKING_NAF_OPTIONS}
                />
                <FooterButtons />
              </>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    isNHSApplication: isNHSApplication(state),
    form: state.form.form,
    formSharedTitle: state.multiForm.formSharedTitle,
    pensionName: state.multiForm.pensionName,
  };
};

InvestmentChoicesStep2.propTypes = {
  form: PropTypes.object,
  isNHSApplication: PropTypes.bool,
  formSharedTitle: PropTypes.string,
  pensionName: PropTypes.string,
};

export default connect(mapStateToProps)(InvestmentChoicesStep2);
