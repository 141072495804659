import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Slider, TextField, Typography, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TRACKING_NAF_OPTIONS, sliderMarks } from "../../../../../constants";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

import useStepper from "../../../../../hooks/useStepper";
import Loader from "../../../../generic-components/loader";
import FooterButtons from "../footer/FooterButtons";
import FormHeader from "../header/FormHeader";
import AvcAddToPayroll from "./AvcAddToPayroll";
import { getAvcCalculation } from "../../../../../graphql/queries/getAvcCalculation";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { formatCurrency } from "../../../../../helpers";
import { updatedAvcCost } from "../../../../../graphql/mutations/updatedAvcCost";
import useUserEvent from "../../../../../hooks/useUserEvent";
import { setTempPlan } from "../../../../../reducers/tempPlan";

const SharedCostAVCAmountStep3 = ({ form, formSharedTitle }) => {
  const { userTrackingMutation } = useUserEvent();
  const dispatch = useDispatch();
  const {
    handleNext,
    formValues,
    activeStep,
    sharedFormIndex,
    setFormValues,
    setIsLoadingNext,
    setIsLoadingSave,
    setSavedToast,
    setSaveError,
    setErrorToast,
    personalDetails,
    loading,
    trackingCode,
    setActiveStep,
    setSharedFormIndex,
  } = useStepper();
  const [planFrequency, setPlanFrequency] = useState(5);
  const [contributionAmount, setContributionAmount] = useState(
    formValues.additional_avc_amount
  );
  const [planTimeSpan, setPlanTimeSpan] = useState(formValues.planFrequency);

  const [getCalculations, { data, loading: estimateLoading }] = useLazyQuery(
    getAvcCalculation,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        setSaveError(error);
      },
    }
  );

  const [
    getCalculation,
    { data: contributionData, loading: contributionLoading },
  ] = useLazyQuery(getAvcCalculation, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      setSaveError(error);
    },
  });

  const totalProjection = data?.shared_avc_calculation?.total_pot || 0;

  const onSliderChange = (value) => {
    setPlanTimeSpan(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      planFrequency: value,
    }));
  };

  const setSpanTimeDebounce = useCallback(
    debounce((value) => {
      setPlanTimeSpan(value);
      setFormValues((prevValues) => ({
        ...prevValues,
        planFrequency: value,
      }));
    }, 1000),
    []
  );
  const [updateApplication] = useMutation(updatedAvcCost);

  const saveValues = (condition) => {
    if (condition === "save_and_next") {
      setIsLoadingNext(true);
    } else if (condition === "save") {
      setIsLoadingSave(true);
    }

    updateApplication({
      variables: {
        id: form.id,
        total_avc_amount_requested: parseFloat(formValues.avcAmount) || 0,
        additional_avc_amount: contributionAmount,
        contribution_amount_updated: !!formValues.contribution_amount_updated,
        previous_amount_added:
          parseFloat(formValues.previous_amount_added) || 0,
        step_number:
          condition === "save"
            ? activeStep + sharedFormIndex
            : activeStep + sharedFormIndex + 1,
        updated_at: moment().format(),
      },
      onCompleted: (data) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          avcAmount: formValues.avcAmount,
          additional_avc_amount: contributionAmount,
        }));
        if (condition === "save") {
          setSavedToast(true);
        }
        dispatch(setForm(data.update_temp_plans.returning[0]));
        if (condition === "save_and_next") {
          handleNext();
        }
        setIsLoadingNext(false);
        setIsLoadingSave(false);
      },
      onError: (error) => {
        setIsLoadingNext(false);
        setIsLoadingSave(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
    setFormValues((prevValues) => ({
      ...prevValues,
      planFrequency: planTimeSpan,
    }));
  };

  const handleUserEvent = (element) => {
    if (element?.target?.value) {
      userTrackingMutation({
        variables: {
          ...TRACKING_NAF_OPTIONS,
          field_name: element?.target?.name || "",
          field_value: element?.target?.value?.toString() || "",
          avc_track_code: trackingCode,
        },
      });
    }
  };

  const moveToSharedCostPage = () => {
    setActiveStep(1);
    setSharedFormIndex(1);
    setSaveError({
      message: `Please provide the complete required details.`,
      overRideCustom: true,
    });
    setErrorToast(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setTempPlan({ additional_avc_amount: contributionAmount }));
  }, [contributionAmount]);

  useEffect(() => {
    if (formValues.income && formValues.avcAmount) {
      getCalculation({
        variables: {
          annual_salary: formValues.income,
          organisation_id: personalDetails.organisation_id,
          avc_deduction: contributionAmount
            ? parseFloat(formValues.avcAmount) + contributionAmount
            : parseFloat(formValues.avcAmount),
          years: formValues.planFrequency,
        },
      });
    } else {
      moveToSharedCostPage();
    }
  }, [
    contributionAmount,
    formValues.income,
    formValues.planFrequency,
    formValues.avcAmount,
  ]);

  useEffect(() => {
    if (formValues.income && formValues.avcAmount) {
      getCalculations({
        variables: {
          annual_salary: formValues.income,
          organisation_id: personalDetails.organisation_id,
          avc_deduction: formValues.avcAmount,
          years: formValues.planFrequency,
        },
      });
    } else {
      moveToSharedCostPage();
    }
  }, [formValues.income, formValues.planFrequency]);

  const totalPot = useMemo(
    () => contributionData?.shared_avc_calculation?.total_pot || 0,
    [contributionData?.shared_avc_calculation]
  );

  const avcAmount = useMemo(
    () =>
      contributionAmount
        ? Number(formValues.avcAmount || 0) + contributionAmount
        : Number(formValues.avcAmount || 0),
    [formValues.avcAmount, contributionAmount]
  );

  return (
    <Box className="shared-cost-avc-amount-step-3">
      {loading ? (
        <Box className="mt-30">
          <Loader />
        </Box>
      ) : (
        <>
          <Box className="application-page-container">
            <FormHeader
              heading={`${formSharedTitle} Amount`}
              amount={avcAmount}
            />
            <Stack
              direction={{ md: "column", lg: "row" }}
              spacing={2}
              justifyContent="space-between"
            >
              <Stack direction="column" spacing={2}>
                <Typography className="stepper-content">
                  How long do you plan to make contributions to the
                  {""} {formSharedTitle} scheme?
                </Typography>
                <Slider
                  name="planFrequency"
                  value={planFrequency}
                  defaultValue={5}
                  min={1}
                  max={60}
                  valueLabelDisplay="auto"
                  onChange={(e) => setPlanFrequency(e.target.value)}
                  onChangeCommitted={(e, value) => onSliderChange(value)}
                  marks={sliderMarks}
                  className="slider mb-18"
                  onBlur={handleUserEvent}
                  onFocus={handleUserEvent}
                />
              </Stack>
              <TextField
                InputProps={{ min: 1, max: 30 }}
                name="planFrequency"
                value={planFrequency}
                type="number"
                color="mmm"
                onChange={(e) => {
                  const planValue =
                    e.target.value && Math.abs(Number(e.target.value));

                  if (!planValue && planValue !== 0) {
                    setPlanFrequency();
                    setSpanTimeDebounce(0);
                    return;
                  }

                  if (/^(?:[1-9]|[1-5][0-9]|60)$/.test(planValue)) {
                    setPlanFrequency(planValue);
                    setSpanTimeDebounce(planValue);
                  } else {
                    setSpanTimeDebounce(0);
                    setPlanFrequency(0);
                  }
                }}
                size="small"
                label="Years"
                className="planFrequency"
                onBlur={handleUserEvent}
                onFocus={handleUserEvent}
              />
            </Stack>
            <Box className="potworth-amount-container mt-30">
              <Typography className="content stepper-content">
                What your {formSharedTitle} pot is estimated to look like after{" "}
                {planFrequency} years if you contribute{" "}
                {formatCurrency(formValues.avcAmount)}.
                <span className="fs-28">*</span>
              </Typography>
              {!estimateLoading ? (
                <Typography className="amount mt-18">
                  {formatCurrency(totalProjection)}*
                </Typography>
              ) : (
                <Loader className="loader-white" />
              )}
            </Box>
            <AvcAddToPayroll
              planFrequency={planFrequency}
              totalProjection={totalProjection}
              totalPot={totalPot}
              contributionAmount={contributionAmount}
              setContributionAmount={setContributionAmount}
              contributionLoading={contributionLoading}
            />
          </Box>
          <FooterButtons handleClick={saveValues} />
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    formSharedTitle: state.multiForm.formSharedTitle,
  };
};
SharedCostAVCAmountStep3.propTypes = {
  form: PropTypes.object,
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(SharedCostAVCAmountStep3);
