import { Box, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import SharedHeader from "../../generic-components/shared-header/SharedHeader";
import FormHeader from "../../screens/registered-user/shared-cost-application/header/FormHeader";
import MyInvestmentAdivceWithNoPlans from "./MyInvestmentAdivceWithNoPlans";
import MyInvestmentAdvicewithPlans from "./MyInvestmentAdvicewithPlans";
import MyInvestmentAdivceWithPlansNoNingi from "./MyInvestmentAdivceWithPlansNoNingi";
import { useLazyQuery } from "@apollo/client";
import { getNingiDetails } from "../../../graphql/queries/getningijourneyDetails";
import { getAVCamount } from "../../../graphql/queries/getAVCamount";
import useStepper from "../../../hooks/useStepper";
import usePlan from "../../../hooks/usePlan";
import Loader from "../../generic-components/loader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TRACKING_NAF_OPTIONS } from "../../../constants";
import GenericBack from "../../generic-components/generic-back-button";

const MyInvestmentAdvice = ({ organisation }) => {
  const [userWithNingiRecommendation, setUserWithNingiRecommendation] =
    useState([]);
  const [AVCamount, setAVCamount] = useState();
  const [isLoad, setIsLoad] = useState(true);

  const { personalDetails } = useStepper();
  const { isLatestActivePlan } = usePlan();

  const [fetchNingiDetails] = useLazyQuery(getNingiDetails, {
    variables: {
      id: personalDetails.id,
      organisation_id: personalDetails.organisation_id,
    },
    onCompleted: (data) => {
      setUserWithNingiRecommendation(data?.ningi_journeys);
      setIsLoad(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [fetchAVCamount] = useLazyQuery(getAVCamount, {
    variables: {
      plan_id: isLatestActivePlan || 0,
    },
    onCompleted: (data) => {
      setAVCamount(data?.plans[0]?.total_avc_amount_requested);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (personalDetails.organisation_id && personalDetails.id) {
      fetchNingiDetails();
    }
  }, [personalDetails.organisation_id, personalDetails.id]);

  useEffect(() => {
    fetchAVCamount();
  }, []);

  return (
    <>
      <Box className="MyIncestmentAdvice-container space-from-top">
        <SharedHeader heading="My Investment Advice" />
        <Container>
          <GenericBack trackingDetails={TRACKING_NAF_OPTIONS} />
          <FormHeader
            heading={organisation?.name}
            amount={isLatestActivePlan ? AVCamount : null}
          />
          <Box className="MyInvestmentAdvice-wrapper mb-30">
            <Box className="MyInvestmentAdvice">
              {isLatestActivePlan ? (
                isLoad ? (
                  <Loader />
                ) : !userWithNingiRecommendation ||
                  !userWithNingiRecommendation.length ? (
                  <MyInvestmentAdivceWithPlansNoNingi />
                ) : (
                  <MyInvestmentAdvicewithPlans AVCamount={AVCamount} />
                )
              ) : (
                <MyInvestmentAdivceWithNoPlans />
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
  };
};

MyInvestmentAdvice.propTypes = {
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(MyInvestmentAdvice);
